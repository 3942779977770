<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar title="Hỗ trợ trực tuyến" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>
      </van-nav-bar>
    </div>
    <center><div class="servicelistItemImage" style="color: #c24491;"><br><br><br><br>Chăm sóc khách hàng 24/24<br>
   <a :href="this.$store.getters.getBaseInfo.kefu !== undefined ? this.$store.getters.getBaseInfo.kefu : 'https://google.com/'">
        <img src="img/mine/kefu.png" /><font color="#c24491"> CSKH 24/24</font><br>
      </a>
      
    </div></center>  
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    back(){
      return window.history.back();
    }
  },
  created() {

  },
  mounted(){
    /**
     * iframe-宽高自适应显示
     */
    const oIframe = document.getElementById('iframe_web');
    const deviceHeight = document.documentElement.clientHeight;
    oIframe.style.height = (Number(deviceHeight)-65) + 'px'; //数字是页面布局高度差
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.servicelistItemImage {
  width: 300px;
  height: 300px;
}

.servicelistItemImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
