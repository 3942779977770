<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" title="Kết nối cặp đôi" />
		<div class="convention-item">
			<van-tabs animated sticky line-width="auto" :swipeable="true">
				<van-tab title="Tỉnh/Thành Phố">
					<div class="card">Dịch vụ cung cấp gái đẹp miễn phí  trên toàn quốc , Có thể sắp xếp bất cứ nơi nào tại Việt Nam và bất cứ lúc nào  , Bạn chỉ cần liên hệ lễ tân sẽ lập tức sắp xếp.</div>


					<div class="address">
							<van-row class="van-cell-group" justify="" gutter="35" v-for="(val, key) in addlist" :key="key">
							<van-cell  offset="" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{ v.name }}</van-cell>
						</van-row>
				</div><br><br><br><br>
				</van-tab>
				<van-tab title="Giới thiệu nền tảng">
					<div class="card">Nền tảng này cung cấp đầy đủ những trải nghiệm ngoài lề, dẫn kèm kinh doanh và cảm giác đồng thành phố. Để bảo vệ sự riêng tư cá nhân của mỗi người dùng, chỉ có khách hàng được giới thiệu bằng tên thật qua các trạm tiếp đãi hoặc các thành viên cấp cao của nền tảng mới có thể tham gia.</div>
					<div class="rig-box">
						<p class="rig-title">Có những tài nguyên nào?</p>
						<p class="rig-content">Thành viên nổi tiếng mạng, người mẫu, tiếp viên hàng không, người mẫu trẻ, sinh viên đại học, chỉ có điều bạn không nghĩ đến, không có gì mà nền tảng này không thể làm được.</p>
						<p class="rig-title">Dịch vụ phạm vi?</p>
						<p class="rig-content">Tự do tìm người hẹn hò cùng thành phố, nhảy dù khắp đất nước, đều có mặt trong các thành phố lớn và nhỏ, thậm chí cả các thành phố cấp ba cũng có thể sắp xếp thông qua tiếp đãi viên.</p>
						<p class="rig-content">Nền tảng này cung cấp đầy đủ những trải nghiệm ngoài lề, dẫn kèm kinh doanh và cảm giác đồng thành phố. Để bảo vệ sự riêng tư cá nhân của mỗi người dùng, chỉ có khách hàng được giới thiệu bằng tên thật qua các trạm tiếp đãi hoặc các thành viên cấp cao của nền tảng mới có thể tham gia.</p>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>	
</template>


<script>
export default {
  data() {
    return {
      addlist: [
        {
          0: '北京',
          1: '上海',
          2: '广州',
          3: '深圳'
        },
        {
          0: '南京',
          1: '长沙',
          2: '重庆',
          3: '西安'
        }
      ]
    };
  },
  methods: {
    addgo(data) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' });
      } else {
        this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
      }
    },
    getAddress() {
      this.$http({
        method: 'get',
        url: 'address_list'
      }).then((res) => {
        this.addlist = res.data;
      });
    },
    getUserInfo() {
      this.$http({
        method: "get",
        url: "user_info"
      }).then((response) => {
        if (response.code === 200) {
          this.userInfo = response.data;
          this.menu_top = 15;
          if (this.userInfo.logout !== 1) {
            localStorage.clear();
            this.$router.push({ path: "/Login" });
          } else if (this.userInfo.blockroom === 0) {
            this.userInfo.blockroom = 1;
          }
        } else if (response.code === 401) {
          this.$toast(response.msg);
        }
      });
    },
  },
  created() {
    this.getAddress();
    this.getUserInfo();
  }
};
</script>
<style lang="less" scoped>
.align-left {
        text-align: left;
    }
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	background-color: #f2f2f5;
}
.nav-bar {
	background: linear-gradient(90deg,#775fd9,#c24491);
	height: 100px;
}
.van-cell-group{
	background-color:#ffffff;
}
.van-cell{
	padding: 4vw 2.933vw;
    font-size: 4vw;
    line-height: 4vw
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.van-sidebar {
	width: 180px;
}
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #f2f2f5;
}
::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
	color: #c24491;
}
::v-deep .van-tabs__line {
	background-color: #7e5678;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.card {
	background-color: #775fd9;
	padding: 0.625rem;
	width: 95%;
	color: white;
	font-size: 0.8125rem;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}
::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #0bdab0;
	font-size: 1.125rem;
}
.rig-content {
	font-size: 20px;
	// margin-top: 10px;
}
.address {
	width: 94%;
	margin: 0 auto;
	text-align: left;
  }
  
</style>
