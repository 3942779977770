<template>
  <div class="container page">
    <van-nav-bar title="Nạp tiền" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="recharge-form">
      <h1></h1>
      <div class="form-group">
        <label for="bank">Ngân hàng:</label>
        <input type="text" id="bank" :value="this.withdrawRole.nh" readonly />
      </div>
      <div class="form-group">
        <label for="account-number">Chủ tài khoản:</label>
        <input type="text" id="account-number" :value="this.withdrawRole.tentk" readonly />
      </div>
      <div class="form-group">
        <label for="account-number">Số tài khoản:</label>
        <input type="text" id="account-number" :value="this.withdrawRole.stk" readonly />
        <button @click="copyToClipboard(accountNumber)">Sao chép</button>
      </div>
      
      <div class="form-group">
        <label for="amount">Nội dung chuyển tiền:</label>
        <input type="text" id="userInfo.username" :value="userInfo.username" readonly />
        <button @click="copyToClipboard(userInfo.username)">Sao chép</button> <!-- Thêm nút sao chép userInfo.username -->
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      withdrawRole:{},
      menu_top: 40,
      isLoading: true,
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    copyToClipboard(content) {
      const el = document.createElement("textarea");
      el.value = content;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      alert(`Đã sao chép: ${content}`);
    },
    getUserWithdrawRole(){
      this.$http({
        method: 'get',
        url: 'user_get_withdraw_role'
      }).then(res=>{
        if(res.code === 200){
          this.withdrawRole = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    allMoeny(){
      this.withdraw_money = this.userInfo.money;
    },
    doWithdraw(){
      if(this.withdraw_money <= 0){
        this.$toast("Vui lòng điền số tiền chính xác");
        return false;
      }else {
        this.$http({
          method: 'post',
          data:{money:this.withdraw_money},
          url: 'user_set_withdraw'
        }).then(res=>{
          if(res.code === 200){
            location. reload()
            this.$toast(res.msg);
            this.getUserInfo();
            this.getUserWithdrawRole();
          }else if(res.code ===401){
            this.$toast(res.msg);
          }
        })
      }
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.menu_top = 15;
          if (this.userInfo.status !== 1) {
            this.$toast("Tài khoản ngoại tuyến");
            localStorage.clear();
            this.$router.push({ path: '/Login' });
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (localStorage.getItem('token')) {
      this.getUserInfo();
      this.getUserWithdrawRole();
    } else {
      this.userInfo.username = "Đăng ký/Đăng nhập";
      this.userInfo.ip = "Đăng nhập để tận hưởng nhiều dịch vụ hơn!";
      this.userInfo.header_img = "img/mine/avatar.png";
    }
  }
};

</script>


<style lang='less' scoped>
@import "../../assets/css/base.css";
.recharge-form {
  max-width: 1500px;
  width: 90vw; /* Sử dụng 90vw thay vì max-width để co dãn nội dung */
  margin: 0 auto;
  padding: 2vw; /* Sử dụng 2vw thay vì 20px để co dãn theo kích thước màn hình */
  border: 1px solid #ccc;
  border-radius: 1vw; /* Sử dụng 1vw thay vì 5px để co dãn theo kích thước màn hình */
  background-color: #f9f9f9;
}

h1 {
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

button:active {
  background-color: #003080;
}
</style>
