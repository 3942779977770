<template>
  <div class="nHome-detail">
    <div class="nav-bar van-nav-bar van-hairline--bottom">
      <div class="van-nav-bar__content">
        <div class="van-nav-bar__left" @click="back()">
          <i class="van-icon van-icon-arrow-left" style="color: rgb(255, 255, 255);"><!----></i>
        </div>
        <div class="van-nav-bar__title van-ellipsis">Nạp tiền</div>
      </div>
    </div>
    <div class="detail-title">
      {{ model.name }}
    </div>
    <div style="display: flex;">
      <div class="detail-tabs" v-for="(tab, index) in tabs" :key="index" :class="{ active: selectedTab === index }"
        @click="selectedTab = index">
        <img v-if="selectedTab === index" :src="tab.srcActive" alt="" class="detail-tabs-ico" />
        <img v-else :src="tab.src" alt="" class="detail-tabs-ico" />
        {{ tab.title }}
        <div v-if="tab.count">
          ({{ tab.count }})
        </div>
      </div>



      <!-- <div class="detail-tabs">
        <img src="img/detail/png.png" alt="" class="detail-tabs-ico" />
        images
        ({{ model.images.length }})
      </div>
      <div class="detail-tabs">
        <img src="img/detail/speaker.png" alt="" class="detail-tabs-ico" />
        report
        ({{ model.reports.length }})
      </div> -->
    </div>
    <div class="detail-content">

      <div v-if="selectedTab === 0">
        <div class="movie-list-n-img">
          <div class="movie-list-n-img van-image" :data-src="model.avatar" :src="model.avatar" lazy="loaded">
            <img :src="model.avatar" alt="" class="van-image__img" style="object-fit: cover;" />
          </div>
          <div class="movie-list-n-lab">
            {{ model.location }}
          </div>
        </div>
        <div class="movie-list-n-item-bottom">
          <div class="movie-n-time-div">
            <img src="img/home/love.png" alt="" class="movie-list-ico-loves" /><img src="img/home/love.png" alt=""
              class="movie-list-ico-loves" /><img src="img/home/love.png" alt="" class="movie-list-ico-loves" /><img
              src="img/home/love.png" alt="" class="movie-list-ico-loves" /><img src="img/home/love.png" alt=""
              class="movie-list-ico-loves" /><img src="img/home/love.png" alt="" class="movie-list-ico-loves" />
            <div class="movie-list-money">
              <img src="img/home/wallet.png" alt="" class="movie-list-ico-money" />{{ model.price }}
            </div>
            <div class="movie-list-addr">
              <img src="img/home/location.png" alt="" class="movie-list-ico-addr" />
              {{ model.origin }}
            </div>
            <div class="movie-list-notice">
              <img src="img/detail/clock.png" alt="" class="movie-list-ico-notice" />
              {{ model.birthYear }}
            </div>
          </div>
        </div>
        <div class="table-title">Thông số</div>
        <div class="table">
          <div class="table-list">
            <div class="name">Giới thiệu</div>
            <div class="content">{{ model.introduce }}</div>
          </div>
          <div class="table-list">
            <div class="name">Pass</div>
            <div class="content">{{ model.pass }}</div>
          </div>
          <div class="table-list">
            <div class="name">Năm sinh</div>
            <div class="content">{{ model.birthYear }}</div>
          </div>
          <div class="table-list">
            <div class="name">Cao (cm)</div>
            <div class="content">{{ model.height }}</div>
          </div>
          <div class="table-list">
            <div class="name">Nặng (kg)</div>
            <div class="content">{{ model.weight }}</div>
          </div>
          <div class="table-list">
            <div class="name">Vòng 1 (cm)</div>
            <div class="content">{{ model.measurements.bust }}</div>
          </div>
          <div class="table-list">
            <div class="name">Vòng 2 (cm)</div>
            <div class="content">{{ model.measurements.hips }}</div>
          </div>
          <div class="table-list">
            <div class="name">Vòng 3 (cm)</div>
            <div class="content">{{ model.measurements.waist }}</div>
          </div>
          <div class="table-list">
            <div class="name">Xuất xứ</div>
            <div class="content">{{ model.origin }}</div>
          </div>
          <div class="table-list">
            <div class="name">Lông bím</div>
            <div class="content">{{ model.hair }}</div>
          </div>
          <div class="table-list">
            <div class="name">Mặt</div>
            <div class="content">{{ model.face }}</div>
          </div>
          <div class="table-list">
            <div class="name">Service</div>
            <div class="content">{{ model.service }}</div>
          </div>
          <div class="table-list">
            <div class="name">Phụ phí</div>
            <div class="content">{{ model.extraFees }}</div>
          </div>
          <div class="table-list">
            <div class="name">Nhận dạng</div>
            <div class="content">{{ model.appearance }}</div>
          </div>
          <div class="table-list">
            <div class="name">Thời lượng</div>
            <div class="content">{{ model.duration }}</div>
          </div>
          <div class="table-list">
            <div class="name">Hoạt động</div>
            <div class="content">{{ model.workingHours }}</div>
          </div>
          <div class="table-list">
            <div class="name">Từ chối</div>
            <div class="content">{{ model.rejection }}</div>
          </div>
        </div>
      </div>

      <div v-if="selectedTab === 1">
        <div class="tabs1">
          <van-image v-for="(item, index) in model.images" :key="index" @click="previewImages()" :src="item"
            alt="Preview Image" fit="cover" class="tabs1-img" />
        </div>
      </div>

      <div v-if="selectedTab === 2">
        <div class="table-title">Bình luận</div>
        <div class="commentList">
          <div class="comment">
            <div class="commentList-content" v-for="(item, index) in model.reports" :key="index">
              <img :data-src="item.avatar" alt="" class="commentList-content-avatar" :src="item.avatar" lazy="loaded">
              <div style="width: 100%;">
                <div>
                  <div class="commentList-content-name">{{ item.username }}</div>
                  <div class="commentList-content-txt">{{ item.comment }}</div>
                </div><!----><!---->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ImagePreview } from 'vant';
import 'vant/lib/index.css';
import axios from "axios";

export default {
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      model: null,
      selectedTab: 0,
      tabs: [
        { title: 'Info', src: 'img/detail/info.png', srcActive: 'img/detail/info-active.png' },
        { title: 'Images', src: 'img/detail/png.png', srcActive: 'img/detail/png-active.png', count: 0 },
        { title: 'Report', src: 'img/detail/speaker.png', srcActive: 'img/detail/speaker-active.png', count: 0 },
      ],
      imagePreview: []
    };
  },
  computed: {
    ...mapState({
      models: (state) => state.models,
    }),
  },
  methods: {
    back(){
      return window.history.back();
    },
    onDetail(id) {
      this.$router.push({ name: "detail", params: { phoId: id } });
    },
    async getModel(phoId) {
      const res = await axios.get(`${this.apiUrl}/models/${phoId}`)
      this.model = res.data
      this.tabs[1].count = res.data.images.length
      this.tabs[2].count = res.data.reports.length
      this.imagePreview = res.data.images
    },
    previewImages() {
      ImagePreview({
        images: this.imagePreview,
        startPosition: 0, // Index of the image to show initially
        showIndex: true, // Display image index (e.g., 1/2)
        loop: true, // Enable looping through images
        closeOnPopstate: true, // Close preview on page navigation changes
      });
    },
  },
  mounted() { },
  created() {
    this.getModel(this.$route.params.phoId);
  },
};
</script>
<style lang="less" scoped>

.nav-bar {
  background: linear-gradient(90deg,#f905e5,#e6c3a1)
}


.nHome-detail {
  background: #f2f2f5;
  height: 100vh;
  overflow: auto;
  color: #000;
}

.detail-title {
  font-size: 5.333vw;
  margin: 2.667vw 0;
}

.detail-tabs {
  color: #e74c3c;
  font-size: 4vw;
  flex: 1;
  padding: 5.333vw 2.667vw;
  align-items: center;
  display: flex;
  justify-content: center;
}

.detail-tabs.active {
  color: #f905e5;
}

.detail-tabs-ico {
  width: 4vw;
  margin-right: 1.333vw;
}

.movie-list-n-img {
  width: 100vw;
  height: 100vw;
  position: relative;
}

.movie-list-n-lab {
  display: inline;
  position: absolute;
  top: 2vw;
  left: 2vw;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  background-color: #f905e5;
  width: 42vw;
  overflow: hidden
}

.movie-list-n-item-bottom {
  padding: 4vw;
}

.movie-list-ico-loves {
  display: inline-block;
  width: 5.333vw;
}

.movie-list-money {
  display: flex;
  align-items: center;
  margin-top: 1.333vw;
  font-size: 3vw;
}

.movie-list-ico-money {
  width: 4vw;
  display: inline-block;
  margin-right: 1.333vw;
}

.movie-list-addr {
  display: flex;
  align-items: center;
  margin-top: 1.333vw;
  font-size: 3vw;
}

.movie-list-ico-addr {
  width: 4vw;
  display: inline-block;
  margin-right: 1.333vw;
}

.movie-list-notice {
  display: flex;
  align-items: center;
  margin-top: 1.333vw;
  font-size: 3vw;
}

.movie-list-ico-notice {
  width: 4vw;
  display: inline-block;
  margin-right: 1.333vw;
}

.table-title {
  padding: 4vw;
  font-size: 4.8vw;
  background: #ccc;
  border-bottom: 1px solid #eee;
}

.table .table-list {
  padding: 2.667vw 4vw;
  display: flex;
  background: #eee;
}

.table .table-list .name {
  font-size: 3vw;
  width: 30%;
  display: flex;
  align-items: center;
}

.table .table-list .content {
  width: 70%;
  font-size: 3vw;
}

.table .table-list:nth-child(2n) {
  background: #999;
}

.tabs1 {
  display: flex;
  flex-wrap: wrap;
}

.tabs1-img {
  width: 50%;
  padding: 1.333vw;
}

.table-title {
  padding: 4vw;
  font-size: 4.8vw;
  background: #ccc;
  border-bottom: 1px solid #eee;
}

.comment {
  padding: 4vw
}

.commentList-content {
  display: flex;
  margin-bottom: 4vw;
  font-size: 3.733vw
}

.commentList-content-avatar {
  width: 8vw;
  height: 8vw;
  margin-right: 2.667vw
}


.commentList-content-name {
  margin-bottom: 1.333vw;
  color: #f905e5;
}
</style>
