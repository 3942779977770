<template>
  <div class="home-container">
    <div class="van-list">
      <div class="movie_list_n">
        <div class="movie-list-n-item" v-for="(v, key) in models.docs" :key="key" @click="onDetail(v._id)">
          <div class="movie-list-n-title">{{ v.name }}</div>
          <div class="movie-list-n-img">
            <div class="movie-list-n-img van-image" :src="v.avatar" lazy="loaded">
              <img :src="v.avatar" alt="" class="van-image__img" style="object-fit: cover;" />
            </div>
            <div class="movie-list-n-lab">
              {{ v.location }}
            </div>
          </div>
          <div class="movie-list-n-item-bottom">
            <div class="movie-n-time-div">
              <img src="img/home/love.png" alt="" class="movie-list-ico-loves" /><img src="img/home/love.png" alt=""
                class="movie-list-ico-loves" /><img src="img/home/love.png" alt="" class="movie-list-ico-loves" /><img
                src="img/home/love.png" alt="" class="movie-list-ico-loves" /><img src="img/home/love.png" alt=""
                class="movie-list-ico-loves" /><img src="img/home/love.png" alt="" class="movie-list-ico-loves" />
              <div class="movie-list-notice">
                <img src="img/home/speaker.png" alt="" class="movie-list-ico-notice" />
                {{ v.pass }}
              </div>
              <div class="movie-list-addr">
                <img src="img/home/location.png" alt="" class="movie-list-ico-addr" />
                {{ v.origin }}
              </div>
              <div class="movie-list-money">
                <img src="img/home/wallet.png" alt="" class="movie-list-ico-money" />{{ v.price }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      models: [],
    };
  },
  computed: {
    ...mapState({
      models: (state) => state.models,
    }),
  },
  methods: {
    onDetail(id) {
      this.$router.push({ name: "detail", params: { phoId: id } });
    },
    getUserInfo() {
      this.$http({
        method: "get",
        url: "user_info",
      }).then((response) => {
        if (response.code === 200) {
          this.userInfo = response.data;
          this.menu_top = 15;
          if (this.userInfo.logout !== 1) {
            localStorage.clear();
            this.$router.push({ path: "/Login" });
          } else if (this.userInfo.blockroom === 0) {
            this.userInfo.blockroom = 1;
          }
        } else if (response.code === 401) {
          this.$toast(response.msg);
        }
      });
    },
    async getModelList() {
      const res = await axios.get(`${this.apiUrl}/models`,)
      if (res.status === 200) {
        this.models = res.data
      } else if (res.code === 401) {
        this.$toast("Lỗi");
      }
    },
  },
  mounted() {
    // console.log(this.models, 'models');
    // Code khi component đã render
  },
  created() {
    this.getUserInfo();
    this.getModelList();
  },
};
</script>
<style lang="less" scoped>
.home-container {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
  height: 100%;
}

.movie_list_n {
  display: flex;
  flex-wrap: wrap;
  margin: 1.333vw;
  padding-bottom: 16vw;
}

.movie-list-n-lab {
  display: inline;
  position: absolute;
  top: 2vw;
  left: 2vw;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #f905e5;
  width: 42vw;
  overflow: hidden;
  font-size: 2vw;
}

.movie-list-n-item {
  width: calc(50% - 1.467vw);
  background-color: #fff;
  border: 1px solid #fff;
  color: #000;
  margin: 2.667vw 0.667vw;
  border-radius: 2.667vw;
  padding: 1.333vw;
}

.movie-list-n-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 2.667vw;
  font-weight: 700;
  font-size: 2vw;
}

.movie-list-n-img {
  width: 44vw;
  height: 44vw;
  position: relative;
}

.movie-list-n-img {
  width: 44vw;
  height: 44vw;
  position: relative;
}

.van-image__error,
.van-image__img,
.van-image__loading {
  display: block;
  width: 100%;
  height: 100%;
}

.movie-n-time-div {
  margin-top: 2.667vw;
}

.movie-list-ico-loves {
  display: inline-block;
  width: 5.333vw;
}

.movie-list-notice {
  display: flex;
  align-items: center;
  margin-top: 1.333vw;
  font-size: 2vw;
}

.movie-list-ico-notice {
  width: 4vw;
  display: inline-block;
  margin-right: 1.333vw;
}

.movie-list-addr {
  display: flex;
  align-items: center;
  margin-top: 1.333vw;
  font-size: 2vw;
}

.movie-list-ico-addr {
  width: 4vw;
  display: inline-block;
  margin-right: 1.333vw;
}

.movie-list-money {
  display: flex;
  align-items: center;
  margin-top: 1.333vw;
  font-size: 2vw;
}

.movie-list-ico-money {
  width: 4vw;
  display: inline-block;
  margin-right: 1.333vw;
}
</style>
